
@import url('https://fonts.googleapis.com/css2?family=Lusitana&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Text&display=swap');

@font-face {
  font-family: Tafelschrift;
  src: local('Tafelschrift'), url('./assets/font/Tafelschrift.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


body {
  background: #000;
  color: #000;
}
.carousel-inner {
  height:100%;
}
.carousel-indicators,.carousel-control-prev {
  
  z-index:100
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
