

@font-face {
  font-family: 'Tafelschrift';
  src: local('Tafelschrift'), url('./assets/font/Tafelschrift.ttf') format('truetype');
}
@font-face {
  font-family: 'Berton';
  src: local('Berton'), url('./assets/font/Berton.ttf') format('truetype');
}
@font-face {
  font-family: 'BertonV';
  src: local('BertonV'), url('./assets/font/BertonV.ttf') format('truetype');
}
@font-face {
  font-family: 'FloatingTin';
  src: local('FloatingTin'), url('./assets/font/FloatingTin.ttf') format('truetype');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.swiper-pagination-bullets{
  display: flex;
  justify-content: center;
  top:0;
  
} 
.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  /* border: 1px #b49a02 solid;
  border-radius: 100em; */
  background-color: whitesmoke !important;
}
.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet-active{
 transition:ease-in;
 transition-duration: 300ms;
 transform: scale(1.2);
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
